exports.components = {
  "component---src-features-quality-assurance-mdx": () => import("./../../../src/features/quality-assurance.mdx" /* webpackChunkName: "component---src-features-quality-assurance-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-features-quality-assurance-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/features/quality-assurance.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-features-quality-assurance-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-1-blockchain-or-bullshit-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/1_Blockchain_or_Bullshit.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-1-blockchain-or-bullshit-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-10-story-of-on-chain-governance-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/10_story-of-on-chain-governance.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-10-story-of-on-chain-governance-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-11-token-bonding-curves-part-1-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/11_token_bonding_curves_part1.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-11-token-bonding-curves-part-1-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-12-token-bonding-curves-part-2-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/12_token_bonding_curves_part2.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-12-token-bonding-curves-part-2-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-13-the-wyoming-dao-act-understanding-key-definitions-and-legal-considerations-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/13_the-wyoming-dao-act-understanding-key-definitions-and-legal-considerations.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-13-the-wyoming-dao-act-understanding-key-definitions-and-legal-considerations-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-14-mesh-networks-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/14_mesh-networks.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-14-mesh-networks-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-15-mastering-software-quality-assurance-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/15_mastering-software-quality-assurance.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-15-mastering-software-quality-assurance-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-2-the-risks-of-using-bitcoin-mixers-on-tor-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/2_The_Risks_of_Using_Bitcoin_Mixers_on_TOR.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-2-the-risks-of-using-bitcoin-mixers-on-tor-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-3-airdrop-problem-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/3_Airdrop_Problem.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-3-airdrop-problem-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-4-the-rise-of-nf-ts-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/4_The Rise of NFTs.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-4-the-rise-of-nf-ts-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-5-zora-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/5_Zora.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-5-zora-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-6-enforce-nft-royalties-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/6_enforce_NFT_royalties.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-6-enforce-nft-royalties-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-7-the-limits-of-statistical-zero-knowledge-proof-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/7_the_limits_of_statistical_zero_knowledge_proof.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-7-the-limits-of-statistical-zero-knowledge-proof-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-8-dictionary-for-zero-knowledge-terminlogy-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/8_dictionary-for-zero-knowledge-terminlogy.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-8-dictionary-for-zero-knowledge-terminlogy-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-9-on-chain-governance-benefits-and-how-to-use-jokedao-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/src/posts/9_on-chain-governance-benefits-and-how-to-use-jokedao.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-src-posts-9-on-chain-governance-benefits-and-how-to-use-jokedao-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

